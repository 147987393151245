@font-face {
    font-family: "cy-custom-icons";
    src: url("./cy-custom-icons.eot?e6f208249cb6076e13080934283210fa?#iefix") format("embedded-opentype"),
url("./cy-custom-icons.woff2?e6f208249cb6076e13080934283210fa") format("woff2"),
url("./cy-custom-icons.woff?e6f208249cb6076e13080934283210fa") format("woff"),
url("./cy-custom-icons.ttf?e6f208249cb6076e13080934283210fa") format("truetype"),
url("./cy-custom-icons.svg?e6f208249cb6076e13080934283210fa#cy-custom-icons") format("svg");
}

i.icon.cy_load_icon,
i.icon.cy_unload_icon,
i.icon.cy,
i.icon.fuel,
i.icon.icon_order_id {
    font-family: cy-custom-icons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

i.icon.cy_load_icon:before {
    content: "\f101";
}
i.icon.cy_unload_icon:before {
    content: "\f102";
}
i.icon.cy:before {
    content: "\f103";
}
i.icon.fuel:before {
    content: "\f104";
}
i.icon.icon_order_id:before {
    content: "\f105";
}
